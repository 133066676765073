import React, { useState } from 'react';
import { Box, colors, Grid, Typography } from '@mui/material';
import PlanTypeButton from './PlanTypeButtons/PlanTypeButton';
import ToggleSwitch from './ToggleSwitch';
import PricingCards from './PricingCards';
import pricingData from './PricingData.json';
import './pricing.css';
import FAQ from './FAQ/Faq';
import faqData from './faq.json';
import BoxComponent from '../BoxComponent/BoxComponent';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HadshakeOutLinedIcon from '../../Assets/icons/HadshakeOutLinedIcon.svg';
import SupportIcon from "../../Assets/icons/SupportIcon.svg";
import {useNavigate, Link} from 'react-router-dom';

const Pricing: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('PROFESSIONALS');
  const [isYearly, setIsYearly] = useState<boolean>(true);
  
  const currentData = pricingData[selectedTab as keyof typeof pricingData];
  const currentPrices = currentData[isYearly ? 'yearly' : 'monthly'];
  const description = currentData.description;
  const content = currentData.content;
  const navigate = useNavigate();
  const handleClick = () => {
    window.location.href = 'mailto:info@glam9.com';
  };
  const ContactNow = () => {
    navigate('/pricing');
  };  
  


  return (
    <Box className="homepageClasses">
      {/* Header */}
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          sx={{
            background: '#f6f6f6',
            fontSize: { xs: '25px', sm: '40px', lg: '60px' },
            color: '#231F20',
            fontWeight: '300',
            paddingTop: '145px',
            paddingBottom: '145px',
          }}
        >
          Find the right plan for
          <br />
          <span style={{ fontWeight: '700' }}> Your Needs</span>
        </Typography>
      </Box>
      <Box className="subscrition">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            className="subscrition-box"
            display="flex"
            justifyContent="space-between"
            spacing={5}
          >
            <Grid item lg={6} md={12}>
              <Typography
                textAlign={'start'}
                sx={{
                  fontSize: { xs: '25px', sm: '40px', lg: '50px' },
                  color: 'black',
                  fontWeight: 500,
                }}
              >
                Subscription{' '}
                <span style={{ color: '#0178fa', fontWeight: 'bold' }}>
                  Plans​
                </span>{' '}
              </Typography>
              <Typography
                textAlign={'start'}
                sx={{
                  fontSize: { xs: '14px', sm: '16px', lg: '18px' },
                  fontWeight: 400,
                  color: '#7a7a7a',
                }}
              >
                Glow All Year – Unlock Unlimited Pampering with Our Exclusive
                Subscription Plans!
              </Typography>
            </Grid>
            <Grid item lg={6} md={12} className="subscrition-headeing">
              <PlanTypeButton
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              {/* <ToggleSwitch isYearly={isYearly} setIsYearly={setIsYearly} /> */}
            </Grid>
          </Grid>
        </Grid>
        <PricingCards
          prices={currentPrices}
          isYearly={isYearly}
          description={description}
          content={content}
          selectedTab={selectedTab}
        />
        <FAQ data={faqData} />

        <Grid className="contact-box">
          <BoxComponent
            icon={<img src={HadshakeOutLinedIcon} alt="Handshake Icon" />}
            title="Need more help?"
            description="Unlock Unlimited Pampering with Our Exclusive Subscription Plans!"
            buttonText="info@glam9.com"
            onButtonClick={handleClick}
          />
          <BoxComponent
            icon={<img src={SupportIcon} alt="Support Icon" />}
            title="Looking for support?"
            description="Visit our support portal for product or account help."
            buttonText="Contact Now"
            onButtonClick={ContactNow}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default Pricing;
 