import React from 'react';
import { Button, Box } from '@mui/material';
import './TimeSelector.css';

type TimeSelectorProps = {
  timeSlots: any;
  setSelectedTime: Function;
  selectedTime: string;
  selectedDate: string;
  checkAppointmentAvaibilityAPICallFunc: Function;
};

const TimeSelector: React.FC<TimeSelectorProps> = ({
  timeSlots,
  setSelectedTime,
  selectedTime,
  selectedDate,
  checkAppointmentAvaibilityAPICallFunc,
}) => {
  return (
    <Box className="time-selector">
      {timeSlots.map((data: any, index: number) => (
        <Button
          onClick={() => {
            setSelectedTime(data.time);
            checkAppointmentAvaibilityAPICallFunc(selectedDate, data.time);
          }}
          key={index}
          disabled={data.slot_type === '4'} // Disable button when slot_type is "4"
          variant="outlined"
          className={`time-button ${selectedTime === data.time ? 'selected' : ''}`}
        >
          {data.time}
        </Button>
      ))}
    </Box>
  );
};

export default TimeSelector;
