import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import './TermsOfServices.css'; // Add your custom styles if necessary
import { useNavigate, Link, useLocation } from 'react-router-dom';



const TermsOfUse = () => {
    const location = useLocation();
    return (
        <>
        <Container maxWidth="lg" className="termsofservices-container">
                            <Typography variant="h4" component="h1" sx={{ textAlign: 'center', mb: 4, mt: 4 }}>
                                TERMS AND CONDITIONS
                            </Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    padding: 2,
                                    marginBottom: 10,
                                    backgroundColor: '#ffffff',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                
                                <Typography variant="body1" paragraph>
                                    By accessing this application/portal/platform : <Link style={{color:'blue', cursor:'pointer',textDecoration: 'none' }} to='/'>https://glam9.com/</Link> and other as may be launched from time to time (hereinafter referred to as "Glam9" and/or "Platform" and/or "Application" and/or "Website", which shall mean and be used interchangeably having the same meaning) is developed, operated and maintained by Glam9 Solution Private Limited ("The Company"), a Company, having registered office at 205, Iscon Elegance, Besides Hotel Crown Plaza, S.G. Highway, Ahmedabad-380051, Gujarat, India, you agree to be legally bound by following terms and conditions and other incidental or related aspects for use.
                                    Before you use the platform, you must read all of the terms and conditions (''Terms") herein and the Privacy Policy provided on the Application. Please also refer the additional legal information as may be applicable to you. You must be at least [18] years of age to use our platform; by using our platform and agreeing to these terms and conditions, you warrant and represent to us that you are at least [18] years of age; Audience less than 18 years of age should register with credentials of Parent(s) and/or Legal Guardian. Platform shall not be responsible to validate and authenticate your age, however, platform reserves the right to optionally/randomly conduct checks to verify the correctness of legal age of your’s/your legal guardian’s age registering on platform on your behalf.
                                    "we", "our" and "us" "Glam9 Solutions Private Limited” means "the company"; "you", "user" and "your" means any person and/or the institutions/organizations etc., but not limited to, who accesses and uses this Platform along with updating their users on this platform;
                                    It is strongly recommended to you to visit this page periodically to review the most current version of the Terms and Conditions. The company reserves the right at any time, at its sole discretion, to change or otherwise modify the Terms without prior notice, and your continued access or use of this platform signifies your acceptance of the updated or modified Terms. If you object to these Terms or any subsequent modifications to these Terms or become dissatisfied with the platform in any way, you should immediately terminate use of the platform.
                                </Typography>
                                {/* Repeat similar Typography blocks for other sections */}
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    PART A: GENERAL TERMS AND CONDITIONS OF USE
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    This Terms and Conditions/Agreement is an Electronic Record published in terms of Information Technology Act, 2000 of India and generated by a computer system and does not require any physical or digital signatures and is in accordance with the provisions of Rule 3 of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 issued under the aforesaid Act.
                                    Before you further use this Platform, you must read all of the terms and conditions specified (all sections of this Platform including but not limited to the General Terms and Conditions of Use, the Special and Additional Terms and Conditions of use and Privacy Policy provided on this Platform) collectively hereinafter referred to as the Agreement.
                                    By using these Products, Services and the Platform (hereinafter referred to as Services), you hereby confirm to having accepted the Agreement, with immediate effect.
                                    If you do not agree to the aforesaid paragraph, please do not proceed to further on this Platform.
                                    If this Agreement conflicts with any other document(s), this Agreement will override and supersede such other document(s) and in case of conflict between Part A (General Terms and Conditions of Use) and Part B (Special and Additional Terms and Conditions of Use), the specific provisions of Part B shall prevail. Apart from it, all the terms and conditions which are obligatory to or binding on you will be applicable.
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    A.	GENERAL
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    To the extent that any provision of this Agreement is found to be invalid, unlawful or unenforceable you agree that the courts at Ahmedabad shall have exclusive jurisdiction over the matter and shall endeavor to give effect to the Parties' intentions as reflected in the provision, and the unenforceable condition shall be deemed severable and shall not affect the validity and enforceability of any remaining terms and conditions.
                                    Headings are for reference purposes only and do not limit the scope or extent of such section.
                                    The failure of the company to act with respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches. The company does not guarantee it will take action against all breaches of this Agreement.
                                    Except as otherwise expressly provided in this Agreement there shall be no third-party beneficiaries to this Agreement. This Agreement constitutes the entire agreement, subject to specific provisions herein, between you and the company and governs your use of the Platform and the Services, superseding any prior agreements or any communication written or oral by any mode between you and the company with respect to the Platform and/or the Services.
                
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    B.	DESCRIPTION OF SERVICES/PURPOSE:
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The Company, through its Application, provides a platform to facilitate connections between users and various service providers, including but not limited to salons, spas, freelance professionals, proprietors of beauty parlors, henna artists, hairstylists, and other similar service providers (collectively referred to as “Partners”).  The platform allows users to explore and book a wide range of beauty, wellness, and grooming services provided by salons, spas, clinics and other professionals in their vicinity. Through an easy-to-use interface, users can browse available services, customize their preferences, add them to the cart, and confirm bookings with a simple click. Glam9 is designed to make the process seamless and enjoyable, ensuring that relaxation, self-care, and indulgence are just a few taps away. By leveraging technology, Glam9 brings the beauty and wellness industry closer to users, providing a hassle-free solution for scheduling appointments and enhancing their overall experience. By utilizing the services, users agree to comply with all relevant terms and conditions outlined by the Company .
                                    The User shall be bound by the Terms of Use and all copyright policies and other applicable laws for any purpose regardless of whether the User is availing any paid services or not. Content hosted on the platform is licensed, and not sold to the User. Company reserves the right to introduce additional terms in future as may be mandated by compliant authorities and/or otherwise. User may use company’s Applications /portal only on Supported Devices (as may be conveyed from time to time by company) that are compatible with the software. To access the application, User may be required to download, install and run certain software, and updates and upgrades thereto, owned or controlled by company and/or its service provider(s). Such software shall be governed by the accompanying software or end-user license agreement, and User shall comply with the terms thereof, the terms of which are incorporated herein.
                                    While using and accessing this platform, user provides consent to store various information of user like login information, name, addresses, email, phone number, user’s option to register through Gmail, geographical or pin location etc. User understands that some or all of these may be specified under personal information and user unconditionally provides Company their consent to store, use, process, transmit etc., this information. Additionally, user provides consent to company allowing company to use various analytics tools like google / web analytics etc., over such data of user. Company further collects and store other information that may include, users name, number email etc., but not limited to, to which user completely provides their consent hereof. Users also hereby consent that the Company at its discretion may send push notification to the User from time to time.
                                    In course of providing user-friendly and hassle-free service/experience to the user and to provide end services, the company may be using third-party services. In such case, direct or indirect integrations will be made with such associated third-party service providers to render certain services.
                                    Any data provided by you shall be used preliminary for this purpose or any other purpose connected to fulfill the above purpose or to enhance the user experience.
                
                                </Typography>
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    4.	 PROCESSING PERSONAL INFORMATION:
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The company and its partners collect and use your personal information and other information only as follows ("The purpose"):
                                    <ul className='romanNumber'>
                                    <li>	To send you any administrative notices, offer alerts and other Transactional Communications relevant to your use of the Service.</li>
                                    <li>	To enable you to apply for certain services for which you have chosen to apply.<br></br></li>
                                    <li>	For market research, project planning, troubleshooting issues, detecting and protecting against error, fraud or other criminal activity.</li>
                                    <li>	To third-party contractors that provide services to the company and are bound by these same privacy restrictions & confidentiality obligations.</li>
                                    <li>	To enforce Company’s terms & conditions.</li>
                                    <li>	To use data in connection with legal claims, compliance, regulatory and investigative purposes as necessary (including disclosure of such information in connection with legal process or litigation).;</li>
                                    <li>	To analyze usage of the application/site and to improve the Service/s and security.</li>
                                    <li>	To otherwise as set forth in this Privacy Policy.</li>
                                    <li>	To perform various analytics for the betterment of the user experience/services</li>
                                    <li>	To carry out obligations arising from any contracts entered into between the User and company and to provide User facilities and services as per Users interest.</li>
                                    <li>	To provide User with information about other services, events and services that companies offer that are similar to this User have already purchased or inquired about or other communications containing information about new services or upcoming events of the company, company’s affiliates and non-affiliated third parties such as societies and sponsors, if User has consented to receive this information.</li>
                                    <li>	To notify the User about changes or updates to the application’s User account.</li>
                                    <li>	To respond to the Users to requests, inquiries, comments or concerns.</li>
                                    <li>	To allow the User to participate in interactive features of our service when User chooses to do so.</li>
                                    </ul>
                
                                </Typography>
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    C.	REGISTRATION:
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Registration with the Platform is by way of creating user ID. You are solely responsible and liable for the veracity and accuracy of all personal and other details furnished by you as well as for authentication of all documents uploaded by you at the time of registration with the Platform and afterwards.
                                    You hereby agree that the company may contact you either electronically, through phone or through its online and offline centers or associates, to understand your interest in the selected services and to fulfill your demand. You also agree that the company /the platform reserves the right to make your details available to its partners and affiliates and you may be contacted by such partners and affiliates for information through email, telephone, SMS and/or any other mode of communication.
                                    You hereby specifically grant a consent to receive, Transactional or Service Explicit/Implicit communications, promotional materials and/or special offers from the company through email, SMS and/or any other mode of communication.
                
                                </Typography>
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    D.	PROPRIETARY RIGHTS:
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    You acknowledge and agree that the company owns all legal rights, titles and interests in and to the Platform and the Services offered herein, including any intellectual property rights which subsist in the Platform, technology, other intellectual property rights and/or the Services (whether those rights are registered or not, and wherever in the world those rights exist). All the content displayed on the application/website is the sole property and is in the exclusive right, title and ownership of the Company. The copyright exists in the content on the application/website as well as the application/website itself is and shall always remain in the sole custody, possession, control and ownership of the Company. Copyright for this purpose includes registered as well as any unregistered copyright. You further acknowledge that the Platform may contain information which is designated confidential by the company and that you shall not disclose such information without the company's prior written consent. You may not copy, create a derivative work from, modify, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, grant a security interest in or otherwise transfer any right in the Platform and/or the Services.
                                    The trademarks, logos, designs and service marks ("Marks") displayed on this application/website are the property of the Company (and/or the respective institutes, organizations, publications etc., respectively) and shall always remain the sole property of the Company. Trademarks and domain name of the Site also vests with the Company. Trade marks that have been applied for registration and the unregistered trademarks shall also be the sole property of the Company. You do not have the right to use any of the company's trade names, trademarks, service marks, logos, domain names, and other distinctive brand features. You do not have the right to remove, obscure, or alter any proprietary rights notices (including trademark and copyright notices), which may be affixed to or contained within the Services or on the Platform. You will not copy or transmit any of the Services, components of service, data, details or information. You are strictly prohibited to modify, copy, alter, change, distribute, display, publish, transform, transfigure and/or mutate any part of the Platform and/or the Services outside the platform and/or in any manner apart from the one permissible on the platform from time to time.
                                    Company’s application/website (current as well as any other launches of future) respect the intellectual property rights of our users, Content Providers, organizations, institutes, publications and other third parties and expects our users to do the same when using the Services. Users shall be equally bound by confidentiality clauses and all other applicable clauses towards maintaining the sanity of the meaning of the clauses mentioned in this agreement, not only towards the company but also towards the respective content providers, source code providers, service providers, 3rd party etc., but not limited to.
                
                
                                </Typography>
                
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    E.	LICENSE AND PLATFORM ACCESS/USAGE OF THE PLATFORM:                </Typography>
                                <Typography variant="body1" paragraph>
                                    The company grants you a limited license to access and make use of the Platform, the Products/Services offered purely for commercial purpose only.
                                    This license does not include any copying of any kind of information or in any forms such as extract or reformatted for the benefit of another individual, vendor or any other third party who have not subscribed/paid for the platform’s services; caching, unauthorized hypertext links to the Platform and the framing of any content available through the Platform, uploading, posting, or transmitting any content that you do not have a right to make available (such as the intellectual property of another party) outside the application; uploading, posting, or transmitting any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; any action that imposes or may impose (in the company's sole discretion) an unreasonable or disproportionately large load on the company's infrastructure; or any use of data mining, robots, or similar data gathering and extraction tools.
                                    You may not bypass any measures used by the company to prevent or restrict access to the Platform and/or the Services. Any unauthorized use by you shall terminate the permission or the limited license granted to you by the company and shall entail charges of compensation to be paid by you as may be determined by the company.
                                    By using the Platform, you (user) agree not to:
                                    <ul>
                                    <li>1.	Use this Platform or its contents for any purpose other than as defined in the Platform;</li>
                                    <li>2.	Make any speculative, false, or fraudulent transaction or any transaction in anticipation of demand;</li>
                                    <li>3.	Access, monitor or copy any content or information of this Platform using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission;</li>
                                    <li>4.	Violate the restrictions in any robot exclusion headers on this Platform or bypass or circumvent other measures employed to prevent or limit access to this Platform;</li>
                                    <li>5.	Take any action that imposes, or may impose, in our discretion, an unreasonable or
                                    disproportionately large load on our infrastructure;</li>
                                    <li>6.	Deep link to any portion of this Platform (including, without limitation, the purchase path for any service) for any purpose without our express written permission; or</li>
                                    <li>7.	"Frame", "Mirror" or otherwise incorporate any part of this Platform into any other Platform without our prior written authorization.</li>
                                    <li>8.	Use, post etc., any content which is defamatory, obscene, pornographic, pedophilic, invasive of another’s privacy, including bodily privacy, insulting or harassing on the basis of gender, libelous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force. Such act may constitute of criminal proceedings against the user at his/her own risk, damage and consequences. Notwithstanding the fact that recorded contents or other parties involved in creating, producing, or delivering the Site may monitor or review any links to the Site, company and such parties assume no responsibility or liability which may arise from the content thereof.</li>
                                    <li>9.	Is harmful to child and/or impersonates any other person</li>
                                    <li>10.	Infringes any patents, trademarks, copyrights, Proprietary rights and /or violates any law for the time being in force</li>
                                    <li>11.	deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact</li>
                                    <li>12.	threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting other nation</li>
                                    <li>13.	is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person;</li>
                                    <li>14.	Breach any restrictions, guidelines that may be imposed by competent authorities from time to time</li>
                                    <li>15.	Use Downloaded Content upon or after the expiration of the Use Period and acknowledge that any expired Download Content will be automatically deleted from User without further notice to user</li>
                                    <li>16.	Remove any proprietary notices or labels on the Site or Site Content.</li>
                                    <li>17.	Use the Site, Site Content or Service, or any portion thereof, for any purposes which are unlawful in any nation or jurisdiction in the world or for any commercial purpose whatsoever.</li>
                                    <li>18.	Use the Site, Site Content or Service in conjunction with any device or service designed to circumvent technological measures employed to control access to, or the rights in, a content file or other work protected by the copyright laws of any jurisdiction</li>
                                    </ul>
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    F.	YOUR ACCOUNT
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    You represent and warrant that you/ user on whose behalf this account is being created /to be created and used (Parent or Legal Guardian; shall be jointly, severally and interchangeably used as “You”, “Your” etc.), are of legal age and not a minor, competent to contract, having a sound mind, and under no coercion or undue influence of any person or substance or incapacitated in any manner whatsoever to form a binding contract and are not a competitor of the company or in any way or manner associated with competitor or potential competitor or a person barred from receiving the Services offered by the company under the laws of India or other applicable jurisdiction. You will use the Platform to make legitimate use for you or for any another person for whom you are legally authorized to act (and will inform such other persons about the Agreement and/or Privacy Policy) that apply to the use of the Platform and the Services on their behalf (including all rules and restrictions applicable thereto). You also agree to provide true, accurate, current and complete information about yourself as required by the Platform. If you provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or the company has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, the company has the right to refuse and/or deny access of any and all existing or future use by you of the Platform (or any portion thereof).
                                    Notwithstanding the above, the company retains the right at its sole discretion to deny access to anyone to the Platform and/or the Services it offers, at any time and for any reason, including, but not limited to, for violation of the Agreement or Privacy Policy or any other policy that maybe in force with or without reason.
                                    User shall be responsible for creation and saving of their own user Id and password (Strong password containing alpha numeric usage along with special character should be used) and company recommends the same to be changed at regular periodic intervals.
                
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    G.	OUR PARTNERS & CONSENT
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The company's display on or through the Platform of any product/service options or by any other mode of communication (whether public or private) offered by third parties does not in any way imply, suggest, or constitute any sponsorship, recommendation or approval of the company of any such third parties or of their products. The company does not, through its Platform display, content or in any other manner, provide any recommendation, opinion or advice to you on the third parties or their products/services. You agree that the company and the Platform is in no way responsible for the accuracy, timeliness or completeness of information it may obtain from these third parties. It is specifically clarified that the only role that the company is playing through its Platform is as mentioned in the services / purpose and any interaction with such third party introduced through the Platform is at your own risk, and the company will have no liability or responsibility with respect to the acts, omissions, errors, representations, warranties, breaches or negligence of any such third parties or for any personal injuries, death, property damage, non-performance, delays, defaults, any loss of business or profit or other damages or expenses resulting from your interactions with such third parties.
                                    You hereby undertake and confirm that the information and data furnished by you to the platform is true and correct. Pursuant to this agreement, you acknowledge and confirm that you have consented to share sensitive data provided by you with platform as well as any partners of the platform in future. By registering and proceeding with the Application, you hereby authorize the platform and give your consent for the purposes as may be envisioned by platform from time to time.
                                    While company takes the best in industry measures to safeguard user’s data, it clearly disclaims any obligation as may be imposable onto company. Company reserves the right to delete the entire data of user at any point of time wherein a breach to the stated terms is found, at sole discretion of the company. Company further reserves the rights to segment different user categories like patron users and others etc., and store the information in an archived manner and/or delete the same upon expiration of the subscription / license period. Company hereby confirms that upon request of user for deletion of his/her respective data, company upon evaluation and the then existing rules of retaining data, shall do the needful as requested by user.
                                </Typography>
                
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    H.	LOCAL LAWS
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The company controls and operates this Platform from its headquarters in Ahmedabad (Gujarat), India and makes no representation that the Services offered on the Platform are appropriate or available for use in other locations or jurisdictions. If you use this Platform from other locations/jurisdiction, you are responsible for compliance with applicable local laws (including the taxation aspect, Fiscal and Economic Laws) and regulations including but not limited to the export and import regulations of other countries. Unless otherwise explicitly stated, all marketing or promotional materials found on this Platform are solely directed to individuals, companies or other entities interested to getting on boarded onto platform for the services as may be enlisted therein from time to time. By agreeing to these terms and conditions of use, you confirm and undertake that neither you nor any of your representatives shall have the right to challenge the governing law and jurisdiction as more specifically set out herein and/or in Part B (Special and Additional Terms and Conditions) as the case maybe.
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    11.	RETENTION OF PERSONAL INFORMATION:
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    We need your personal data to:
                                    <ul className='noCircle'>
                                        <li>Provide our services to you.</li>
                                        <li>Manage our business for our legitimate interest</li>
                                        <li>Comply with legal obligations, if any</li>
                                    </ul>
                                    You may choose not to share personal data or withdraw consent but doing so may limit the services we are able to provide you. However, once you choose to withdraw the consent the personal data which has been shared shall continue to remain with us for as long as the applicable law provides.
                                    We may retain your Personal Data for a period of time consistent with the purpose of collecting. We determine the appropriate retention period for Personal Data based on the amount, nature, and sensitivity of your Personal Data, the potential risk of harm from unauthorized use or disclosure, and whether we can achieve the purposes of the processing through other means, as well as the applicable legal requirements (such as applicable statutes of limitation). If there is any information that we are unable, for technical reasons, to delete entirely from our systems post appropriate retention period, we will put in place appropriate measures to prevent any further use of the data.
                
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    I.	LIMITATION OF LIABILITY
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The company and its Subsidiaries, Joint Ventures, Associates, Officers, Directors, Employees, Representatives, Affiliates, and providers (hereinafter referred to ''The company's Associates") will not be responsible or liable for:
                                    (a) any injury, death, loss, claim, act of god, accident, delay, or any direct, special, exemplary, punitive, indirect, incidental or consequential damages of any kind (including without limitation lost profits or lost savings), whether based in contract, tort, strict liability or otherwise, that arise out of or is in any way connected with:
                                    <ul>
                                    <li>1.	any failure or delay (including without limitation the use of or inability to use any component of the Platform), or</li>
                                    <li>2.	any use of the Platform or Services, or</li>
                                    <li>3.	the performance or non-performance by us or the company's Partners , even if we have been advised of the possibility of damages to such parties or any other party, or</li>
                                    <li>4.	any errors or omissions in any services or for any loss or damage incurred as a result of the use of services and/or</li>
                                    <li>5.	user content or the defamatory, offensive, or illegal conduct of any third party</li>
                                    </ul>
                                    (b) any damages to or viruses that may infect your computer equipment or other property as the result of your access to the Platform.<br></br><br></br>
                                    (c) any unanticipated, unforeseen damages, risk and/or claims that may arise due to usage of this platform
                                    In no event shall company, its affiliates, directors, employees, advisors, service providers and/or licensors etc., be liable to user for any claims, proceedings, liabilities, obligations, damages, losses and/or costs in an amount exceeding the amount user / client has paid to company hereunder.
                
                
                                </Typography>
                
                                
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    J.	INDEMNITY
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    You agree to indemnify, release and hold harmless the company and the company's Associates, employees, advisors, third party service providers from any and against all liabilities, claims, causes of action, demands, recoveries, losses, damages, fines, penalties, interests or other costs or expenses of any kind or nature, including reasonable attorneys' fees, or arising out of or related to breach of this Agreement, violation of any law or the rights of a third party, or use of the Platform/Services.
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    14.	TRACKING TECHNOLOGIES / COOKIES:
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    If enabled, we may place cookies on your machine that store small amounts of data on your computer/mobile phone about your visit to any of the pages of this application/website. Cookies are small electronic files that collect information when someone visits a website. They can identify the pages that are being viewed and this can assist us in tracking which of our features appeal the most to you and what content you may have viewed on past visits. Some cookies only exist whilst viewers are online, but 'persistent' cookies are not session-based and remain on the viewer's computer. When you visit this site again, 'persistent' cookies can enable us to customize our content according to your preferences.
                                    Technologies such as: cookies, beacons, tags and scripts are used by Company and our partner entities, affiliates, analytics or service providers such as google Ad sense, google analytics etc. These technologies are used in analyzing trends, administering the site, tracking users' movements around the site and gathering demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.
                                    We use cookies for and on our platform in order to personalize our service for you. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited. You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to the Website.
                
                                </Typography>
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    K.	ELECTRONIC COMMUNICATION
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    When you use the Platform, you are communicating with the company electronically. You consent to receive communications from the company electronically. The company may communicate with you by email/messages or by posting notices on the Platform or by phone or usually available means of communication.
                                    You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. You agree to visit our terms and condition & Privacy policy on company’s website periodically (at least once in a year) and in case you do not agree to the same, kindly cease the usage of the platform immediately.
                
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    L.	PLATFORM-PROVIDED MESSAGE POSTINGS (INCLUDING FORUM)
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The Platform may provide users with the ability to post messages on the Platform. The Company is under no obligation to validate in any manner to review any messages, information, data or content ("Postings") posted on the Platform by users and assumes no responsibility or liability relating to any such Postings.
                                    Notwithstanding the above, the Company may from time to time monitor the Postings on the Platform and may decline to accept and/or remove any Postings. You understand and agree not to use any functionality provided by the Platform to post content or initiate communications that contain:
                                    <ul>
                                    <li>1.	Any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, profane, hateful, racially, terrorism, religious harmony, ethnically or otherwise objectionable material of any kind, including, but not limited to, any material which encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable local, state, national or international law;</li>
                                    <li>2.	Advertisements or solicitations of any kind;</li>
                                    <li>3.	Impersonate others or provide any kind of false information;</li>
                                    <li>4.	Personal information such as messages which state phone numbers, account numbers, addresses, or employer references.;</li>
                                    <li>5.	Messages by non-spokesperson employees of the company purporting to speak on behalf of the company or containing confidential information or expressing opinions concerning the company;</li>
                                    <li>6.	Messages that offer unauthorized downloads of any copyrighted or private information;</li>
                                    <li>7.	Multiple messages placed within individual folders by the same user restating the same point;</li>
                                    <li>8.	Chain letters of any kind; or</li>
                                    <li>9.	Identical (or substantially similar) messages to multiple recipients, advertising any product or service, expressing a political or other similar message(s), or any other type of unsolicited commercial message.</li>
                                    </ul>
                                    This prohibition includes but is not limited to:
                                    <ul>
                                    <li>1.	Using the Platform to send messages to people who don't know you or who are unlikely to recognize you as a known contact;</li>
                                    <li>2.	Using the Platform to connect to people who don't know you and then sending unsolicited promotional messages to those direct connections without their permission; and/or</li>
                                    <li>3.	Sending messages to distribution lists, newsgroup aliases, or group aliases</li>
                                    </ul>
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    M.	BLOGS
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The Company may post blogs on its Platform, which may be written by the Company or its contributing authors. The Company Blogs and their contents are made available for informational and educational purposes only, and do not provide any legal, business, professional or tax advice. You agree and understand that your use of the company's Blogs does not create an attorney-client relationship, and the contents of the company's Blogs do not constitute legal advice. Information provided through Blogs may not reflect the most current legal developments and is not guaranteed to be correct, complete, up-to-date, or applicable to your particular transaction or situation. You should contact an appropriate professional for advice on your particular transaction or situation. The company and all contributing authors to Blogs expressly disclaim any and all liability to any persons or entities with respect to actions they take or do not take based on the contents of Blogs, and you agree that by visiting and viewing Blogs, the company, the company's Associates and the contributing authors to Blogs are not liable or responsible for the contents of the blogs.
                                    The information provided and comments posted in Blogs are exclusively the personal views of the authors, unless otherwise attributed. Information and comments provided through Blogs do not necessarily represent the views of the company or Blog editors and should not be attributed to them unless expressly indicated. The posting of an item on Blogs does not mean that the company or Blog editors approve or disapprove of the selection or contents of that item.
                                    The text, graphics, and their selection and arrangement on Blogs are the copyrighted works of the company and/or its licensors, as applicable. All rights are reserved.
                                    No articles on the Blog may be reproduced without the express written permission of the company. If the company does grant permission to a Platform to reproduce a portion of an article, the text must be accompanied by a link to the full article on the Blog. The text must also be accompanied by a statement asserting that the text has been reproduced with the permission of the company. The company may terminate a grant of permission at any time if it determines that such grant is no longer in the best interests of the company. To obtain a permission to reproduce the article and/or in case of any queries pertaining to functioning of the platform, kindly write to the email id as may be mentioned in Contact us / Support Segment of Platform, from time to time.
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    N.	LINKS AND BROWSER EXTENSION
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The Platform or third parties may provide links to other World Wide Web sites or third-party resources. Such third-party contents and links are provided solely for your convenience and information only. As the company has no control over such sites and resources, you acknowledge and agree that the company is not responsible for the availability of such third party external sites or resources, and does not endorse and is not responsible or liable in any manner whatsoever for any such third party content, advertisements, products or other materials on or available from such third party sites or resources. You further acknowledge and agree that the company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such third party content, goods or services available on or through any such third party site or resource. Company reserves the rights to store and process the browsing history as may be required for internal analysis purposes.                 </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    O.	ACCESS TO PASSWORD PROTECTED/SECURE AREAS                </Typography>
                                <Typography variant="body1" paragraph>
                                    Access to and use of password protected and/or secure areas of the Platform is restricted to authorized users only. Unauthorized individuals attempting to access or accessing these areas of the Platform may be subject to prosecution and/or legal action under the applicable laws.                </Typography>
                
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    P.	MODIFICATION AND NOTIFICATION OF CHANGES
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    The company reserves the right to make changes to the Platform, Services, related policies, payment terms and agreements, this Agreement (all sections) and the Privacy Policy at any time. If the company makes a material modification to this Agreement, you are advised to refer this Agreement from time to time. If you choose to continue using the Platform, you agree that by doing so you will be deemed to accept the Agreement.                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    Q.	SURVIVAL OF TERMS AFTER AGREEMENT ENDS
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Notwithstanding any other provisions of this Agreement, or any general legal principles to the contrary, any provision of this Agreement that imposes or contemplates continuing obligations on a party will survive the expiration or termination of this Agreement.
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    R.	YOUR TELEPHONE CALLS
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Telephone calls that you make to our customer service help line on the numbers mentioned in the Contact Us section of the Platform or calls made by our support desk may be monitored or recorded. This will help us to train our staff and improve our services to you. A recording will only be used under proper and careful supervision. Our customer service help line is available on the times notified in the Contact Us section of the Platform. Your telecom provider will charge you at local rates for calls made to non-toll-free numbers. Any solicitation talks shall not be entertained and you as a user of the Platform are bound to maintain the dignity as a tele-talker. All rules and regulations as applicable to telephone calls/Tele Talks in this regard shall apply and you as a user are bound to comply with the same                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    S.	FEEDBACK
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Your feedback makes us better. Please feel free to share it with us. We will assume no responsibility for reviewing unsolicited ideas and will not incur any liability as a result of any similarities between those ideas and materials that may appear in future the company programs. Please do not reveal trade secrets or other confidential information in your messages to the company. Any and all rights to materials submitted to us become the exclusive property of the company. The company is not bound to act or implement any discretionary measures against any complaint/feedback received. However, the company in good faith shall use its best efforts to use the same in a positive way and shall endeavor to take reasonable efforts to improve/improvise the Platform to the extent possible.
                                </Typography>
                
                                <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>
                                    T.	ADDITIONAL TERMS AND CONDITIONS ON CERTAIN SECTIONS OF THE SITE
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    In addition to Part A of this Agreement, users who desire to access and use specific categories/sections on this Platform for the purpose of accessing the Services, are bound by such special and additional terms and conditions of use governing those categories/sections as more specifically defined in Part B of this Agreement. Users are mandatorily required to review, accept and confirm such special and additional terms and conditions before accessing and using the Services available in such specific categories/sections. By accessing these categories/ sections, you agree to be bound by any such special and additional terms and conditions. In the event the special and additional terms and conditions are inconsistent with any part or portion of Part A of this Agreement, the special and additional terms and conditions of Part B being specific in nature shall control and take precedence.
                
                                    PART B: SPECIAL AND ADDITIONAL TERMS AND CONDITIONS
                
                                    [In addition to the terms and conditions set out in Part A, these Special and Additional Terms and Conditions set out herein are applicable to the User. In Case of conflict between Part A (General Terms and Conditions of Use) and Part B (Special and Additional Terms and Conditions of Use) the specific provisions of Part B will prevail.]
                                    All registered users shall ensure compliance with the following terms and conditions at all times through the Platform of the company (the term shall hereinafter deem to include its third-party partners, employees, affiliates, agents and representatives where the context so requires):
                                    <br></br><br></br>
                                    <strong>A.	USER OBLIGATIONS</strong><br></br><br></br>
                                    The Users understands and guarantes to the Company that:
                                    <ul className='noCircle'>
                                    <li> That all appointments booked through the Glam9 application are facilitated directly between the User and the respective Partner listed on the platform. The Company acts solely as a digital intermediary, enabling the booking of services, and does not assume any responsibility or liability for the quality, safety, suitability, or outcomes of the services provided by the Partners.</li>
                                    <li> In the event of any adverse effects, such as skin allergies, injuries, or bodily harm caused by the use of products, administration of treatments, or provision of services by the Partner, the Company shall not be held liable under any circumstances. The User expressly agrees that any claims for damages, injuries, or losses arising from such incidents shall be directed to the Partner, and the Company is indemnified from all liability in this regard.</li>
                                    <li> Furthermore, it is the sole responsibility of the User to provide complete and accurate information regarding their medical history, including but not limited to allergies, skin sensitivities, pre-existing conditions, or prior injuries, to the Partner before availing of any service. Failure to do so absolves both the Partner and the Company from any liability for adverse outcomes resulting from the User's non-disclosure of such information. The User acknowledges that providing accurate and complete information is crucial to avoid any risks associated with the services provided by the Partner.</li>
                                    <li> The Company cannot be held responsible for any negligence, malpractice, or improper conduct on the part of the Partner. Any disputes, claims, or issues arising from the services rendered or products used shall be resolved directly between the User and the Partner without involving the Company.</li>
                                    <li> By using the Glam9 application and booking services through the platform, the User explicitly consents to this limitation of liability clause and waives any right to hold the Company accountable for claims or damages resulting from services provided by the Partners.</li>
                                    <li> To promptly verify and communicate any changes in their personal details, including but not limited to, their location, contact information, medical history, allergies, or any other information that may affect the services to be provided. Additionally, if there are any changes to the Partner's location, availability, or the services being offered, the User is responsible for checking and confirming such updates prior to booking an appointment.</li>
                                    <li> To verify, check and confirm the details of the services, including but not limited to, the type of treatment, products used, and any risks associated with such services.\</li>
                                    <li> To follow all pre-treatment and post-treatment instructions provided by the Partner to ensure safety and minimize the risk of adverse effects.</li>
                                    <li> In the event of any untoward incidents, injuries, or damages occurring at the Partner's venue, the Company shall not be held liable, and any claims or disputes arising from such incidents shall be solely directed to the Partner.</li>
                                    </ul>
                                    <strong>B.	REFUND POLICY AND RESCHEDULING APPOINTMENT</strong>
                                    <ul>
                                    <li> As we do not collect payments directly and function solely as an aggregator platform, refund claims are not applicable. In the event a user seeks to request a refund, they are required to directly approach the Partner or service provider from whom the service was availed. We shall bear no responsibility or liability for refund processing, as such matters fall within the purview of the respective partner or service provider.</li>
                                    <li> The User may reschedule their appointment, provided the request is made at least four (4) hours prior to the originally scheduled time. Any rescheduling requests made less than four hours before the appointment will not be entertained. To reschedule, the User must notify the Company or the Partner through the designated communication channels, providing the original booking details and preferred new timing. If rescheduled within the allowed time frame, no additional charges will be imposed, although any differences in service cost will be payable at the time of rebooking. Failure to reschedule within the specified time will result in the application of the cancellation policy, and applicable fees may be charged.</li>
                                    </ul>
                                    <strong>C.	CONFIDENTIALITY</strong><br></br><br></br>
                                    The provisions of this Section are in addition to the provisions of the Privacy Policy required to be accepted by the user. All information shared by the user as a registered account holder with the Platform and/or the company shall deem to be confidential information and the Platform has put in reasonable and appropriate protective measures to ensure that confidentiality of the same is protected.
                                    However, if such information is shared with any third parties such party shall be responsible for maintaining the confidentiality of the same as per the applicable laws and shall be liable in case of any breach.
                                    The Platform shall have the right to share the user information with the 3rd parties, service provider, regulatory and/or statutory authorities and/or any other agency as maybe required under applicable laws as well as with its partners, employees, alliances, affiliates, agents and representatives on need to know basis.
                                    All information shared by third parties with the user on the Platform shall also be treated as confidential information (unless the same is available in public) and the user shall take appropriate and reasonable measures in its system to protect the same at its end. Any breach or negligence in this regard at the user’s end leading to any third party claims on the user, the company and/or the Platform shall be handled and settled by the user at its own cost and expense and the company shall not be responsible or liable for the same.
                                    <br></br><br></br><strong>D.	INDEMNITY</strong><br></br><br></br>
                                    A breach or non-compliance of these Special Terms and Conditions by the user can cause severe and irreparable loss and damage to the company, the Platform and/or a third party. The company and the Platform reserve their right to recover all losses, costs and expenses incurred in case the users breaches or fails to comply with any of the provisions of this Part B.
                                    <br></br><br></br><strong>E.	DISPUTE RESOLUTION</strong><br></br><br></br>
                                    Amicable Settlement
                                    If any dispute arises between the Parties in connection with the validity, interpretation, implementation or alleged breach of any provision of this Agreement (Terms of Use, Part A, B and Disclaimers) the disputing Parties hereto shall endeavour to settle such Dispute amicably. The attempt to bring about an amicable settlement shall be considered to have failed if not resolved within thirty (30) days from the date of the Dispute.
                                    Arbitration
                                    If the disputing Parties are unable to amicably settle the Dispute in accordance with above, within the period specified therein, any party to the Dispute shall be entitled to serve a notice invoking this Clause and making a reference to arbitration. The arbitration shall be conducted and managed in an AD-HOC arbitration method in a digital mode. The arbitration shall be conducted under a sole arbitrator which is mutually accepted and selected by both parties. The place of arbitration shall be Ahmedabad (India) and all arbitration proceedings shall be conducted in the English language.
                                    The place of arbitration shall be Ahmedabad (India) and all arbitration proceedings shall be conducted in the English language. Judgment upon any arbitral award rendered hereunder may be entered in court, having the above jurisdiction at Ahmedabad, or application may be made to such court for a judicial acceptance of the award and an order of enforcement, as the case may be.
                                    The Courts at Ahmedabad, India shall have sole and exclusive jurisdiction on all questions relating to the validity of the arbitration provisions and the law governing interpretation of these arbitration provisions.
                                    Notwithstanding the above, you hereby accept that by agreeing to these Terms and Conditions of Use that neither you nor any person acting for and on your behalf shall raise a ZERO COMPLAIN (from any jurisdiction) and you hereby accept and bind yourself to the dispute resolution and jurisdiction clause as set out herein.
                                    <br></br><br></br><strong>F.	GOVERNING LAWS AND JURISDICTION</strong><br></br><br></br>
                                    This Agreement (Terms of Use-including part A and B) and the relationship between you and the company will be governed by the laws of the India without regard to its conflict of law provisions. Subject to the above, the courts located in Ahmedabad (India) shall have exclusive jurisdiction with respect to any legal proceedings that may arise in connection with this Agreement.
                                    Notwithstanding the above, you hereby accept that by agreeing to these Terms and Conditions of Use that neither you nor any person acting for and on your behalf shall raise a Zero Complain/ Zero FIR (from any Jurisdiction) and you hereby accept and bind yourself to the dispute resolution and jurisdiction clause as set out herein and/or in the Special and Additional Terms and Conditions as the case maybe.
                                    <br></br><br></br><strong>G.	WARRANTIES</strong><br></br><br></br>
                                    The company reserves the right to discontinue or alter any or all of website services, and to stop publishing website, at any time at company’s sole discretion without notice or explanation; and save to the extent expressly provided otherwise in these terms and conditions, you will not be entitled to any compensation or other payment upon the discontinuance or alteration of any website services, or if we stop publishing the website.
                                    To the maximum extent permitted by applicable law, we exclude all representations and warranties relating to the subject matter of these terms and conditions, our website and the use of our website.
                                    No warranty:  The company sites, services, and software are provided “as is,” with no warranty, implied or statutory, including, without limitation, warranties of merchantability, fitness for a particular purpose, and non-infringement of proprietary rights. The company disclaims any implied, express, or statutory warranties regarding the security, reliability, timeliness, and performance of the solutions & services, including information or advice obtained through the company.
                                    Other than as expressly set out in these terms or additional, in no event shall Company, its officers, directors, employees, advisors, vendors, service providers and/ or agents, be liable to the user for any direct, indirect, incidental, special, punitive, or consequential damages whatsoever resulting from any (i) errors, mistakes, or inaccuracies of content, (ii) personal injury or property damage, of any nature whatsoever, resulting from users access to and use of our service, (iii) any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein, (iv) any interruption or cessation of transmission to or from our servers, (iv) any bugs, viruses, Trojan horses, or the like, which may be transmitted to or through our service by any third party, (v) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of use of any content posted, emailed, transmitted, or otherwise made available via the Company’s client, whether based on warranty, contract, tort, or any other legal theory, and whether or not company is advised of the possibility of such damages, and/or (vi) the disclosure of information pursuant to these terms of service or privacy policy. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. Some jurisdictions may allow the prudence and enforceability of certain warranties, like the implied warranty of services fitness for a particular purpose etc.; however, to the extent permitted by law, company excludes all such warranties and strongly advises user to proceed with usage of the platform only if he/she agrees with the Terms and Conditions and Privacy Policy of the company as stated herein.
                                    Although the Application may link to other websites (“External Sites”), company is not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any External Site, unless otherwise specifically stated herein. By accessing company’s portal/application/website, User acknowledge and agree that company has not reviewed the External Sites and is not responsible for the content contained on any External Site. User access and use of any External Site is at Users own risk, damages and consequences. The Site may contain information or advertising for third parties. Company expressly denies any endorsement, sponsorship, approval, or agreement with any third party communication made available via the Site. Company shall not be deemed to have considered, reviewed, screened or approved any such third party communication.
                                    <br></br><br></br><strong>H.	TERMINATION</strong><br></br><br></br>
                                    In addition to any other rights of the parties set forth herein, Company may in its sole discretion, restrict, suspend or terminate User access to the Service, in whole or in part and without notice. If User terminates User access to the Application and/or Service based on a breach of any portion of these Terms, Company reserves the right to refuse to provide other services to User in the future, even if User subscribes to such services and offers the benefits of such subscription to its other members or representatives.
                                    <br></br><br></br><strong>I.	FORCE MAJUERE</strong><br></br><br></br>
                                    Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to an event beyond the control of such party including but not limited to any Act of God, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood or any other natural or manmade eventuality outside of our control, which causes the termination of an agreement or contract entered into, nor which could have been reasonably foreseen. Any Party affected by such event shall forthwith inform the other Party of the same and shall use all reasonable endeavors to comply with the terms and conditions of any Agreement contained herein.
                                    You hereby accept the above Special and Additional Terms and Conditions and agree to be bound by the same. Any breach, violation or non-compliance shall entitle the company, the Platform and/or its representatives to take necessary action against me/us. You hereby declare that you are of major age and an individual or an entity legally capable and compatible to enter into this Agreement / contract.
                                    You hereby confirm by clicking on "I Accept" [ ] for having read and understood the Agreement (Terms of Use, all sections - Part A & Part B, Privacy Policy, Refund Policy and Disclaimers, are construed to make total and complete agreement) and accept the same in its entirety. You agree to be bound by all the provisions and sections of this Agreement (as applicable to you). Any breach, violation or non-compliance of the same shall entitle the Company, its representative and/or the Platform to take necessary action including legal action against me/us.
                                    You acknowledge that you have read and have understood these Terms, and that these Terms and Conditions have the same force and effect as a signed agreement by you.
                                    VERSION 1.0
                
                                </Typography>
                
                
                            </Box>
                        </Container>

<Container maxWidth="lg" className="cookies-policy-container">
<Typography variant="h4" component="h1" sx={{ textAlign: 'center', mb: 4, mt: 2 }}>
    COOKIES POLICY
</Typography>

<Box sx={{
    padding: 2, marginBottom: 20,
    backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
}}>
    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Introduction to Tracking Technologies and Cookies:</strong>
        <br />
        At Glam9, we are committed to enhancing your online experience and ensuring the functionality and optimization of our website. To achieve this, we employ various tracking technologies, including cookies.
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>What are Cookies?</strong>
        <br />
        Cookies are small text files stored on your device by a website's server. These files are designed to hold a modest amount of data specific to you and our website, enabling us to deliver a more personalized and efficient service.
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Use of Cookies on Our Website</strong>
        <br />
        Our website utilizes cookies to:
        <ul>
            <li>Remember your preferences and settings, providing a customized browsing experience.</li>
            <li>Analyze user behavior and trends on our website, helping us understand what content and features resonate most with our audience.</li>
            <li>Facilitate the functioning of essential website features, ensuring smooth navigation and access to various services.</li>
            <li>Enable seamless navigation and booking processes for services.</li>
            <li>Deliver personalized advertisements based on your browsing behavior on Glam9 and other platforms.</li>
        </ul>
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Types of Cookies We Use</strong>
        <br />
        <ul>
            <li><strong>Session Cookies:</strong> Temporary cookies that remain in the cookie file of your browser until you leave the site.</li>
            <li><strong>Persistent Cookies:</strong> These remain in the cookie file of your browser for a longer period, depending on the lifetime of the specific cookie. These cookies help us recognize you as a repeat visitor and adapt our content to match your preferences.</li>
        </ul>
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Third-Party Cookies</strong>
        <br />
        In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the website, deliver advertisements on and through the website, and so on. We work with trusted third-party service providers such as Google Analytics and advertising networks to collect information about your interactions with our platform.
        <ul>
            <li><strong>Google Analytics:</strong> Tracks user behavior and provides insights to improve our platform. Learn more about their policies: <Link to="https://policies.google.com/privacy" target="_blank">Google Privacy Policy</Link>.</li>
            <li><strong>Advertising Partners:</strong> Deliver targeted ads and monitor their performance.</li>
        </ul>
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Your Choices Regarding Cookies</strong>
        <br />
        You have the option to either accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
        To decline cookies, adjust the settings within your browser. For more detailed information about cookie management with specific web browsers, refer to your browser's help section or visit these resources:
        <ul>
            <li><Link to="https://support.google.com/chrome/answer/95647" target="_blank">Google Chrome</Link></li>
            <li><Link to="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Mozilla Firefox</Link></li>
            <li><Link to="https://support.apple.com/en-us/HT201265" target="_blank">Safari</Link></li>
        </ul>
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        By continuing to browse our site without adjusting your browser settings to block cookies, you are agreeing to our use of cookies.
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Automated Cookie Management</strong>
        <br />
        We provide a cookie preference tool on our website to help you easily manage your consent. You can adjust your preferences at any time using this tool.
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Please Note:</strong>
        <br />
        Rejecting cookies may limit your access to some areas of our website and reduce the functionality of certain features. By continuing to browse our site without adjusting your browser settings to block cookies, you are agreeing to our use of cookies.
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Changes to Our Cookies Policy</strong>
        <br />
        We may update this Cookies Policy from time to time in response to changing legal, technical, or business developments. When we update our Cookies Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make.
    </Typography>

    <Typography variant="h6" paragraph sx={{fontWeight: '400', fontSize: '16px'}}>
        <strong>Contact Us</strong>
        <br />
        If you have any questions about this Cookies Policy or wish to exercise your rights under applicable data protection laws, please contact us at:
                Email: <Link to="mailto:customerservice@glam9.com">customerservice@glam9.com</Link>
    </Typography>
</Box>
</Container>
</>
    );
};

export default TermsOfUse;
