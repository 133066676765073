import React from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { images } from '../../Assets/images/index';
import Masonry from "@mui/lab/Masonry";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useNavigate } from 'react-router-dom';
import '../about/About.css';
const itemData = [
    {
      img: images.image1,
      title: 'Breakfast',
      rows: 2,
      cols: 2,
    },
    {
      img: images.image2,
      title: 'Burger',
    },
    {
      img: images.image3,
      title: 'Camera',
    },
    {
        img: images.image4,
        title: 'Breakfast',
        rows: 2,
        cols: 2,
      },
      {
        img: images.image5,
        title: 'Burger',
      },
      {
        img: images.image6,
        title: 'Camera',
      }
  ];
const HeroAbout: React.FC = () => {

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
          src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
          srcSet: `${image}?w=${size * cols}&h=${
            size * rows
          }&fit=crop&auto=format&dpr=2 2x`,
        };
      }

    const isMatch = useMediaQuery('(max-width: 900px)');
    const navigate = useNavigate();
        function gotoPricePage(){
            navigate('/pricing');
        }

        const images12 = {
            image1: images.image1,
            image2: images.image2,
            image3: images.image3,
            image4: images.image4,
            image5: images.image5,
            image6: images.image6,
          };    

    return (
        <>
            {/* About Container */}
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} order={{ xs: 1, sm: 1, md: 2 }} md={6}>
                        <Box
                            component="img"
                            src={images.aboutFirstImage}
                            alt="Sample Image"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px',
                                objectFit: 'cover',
                                pointerEvents:'none'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} order={{ xs: 2, sm: 2, md: 1 }} md={6}>
                        <Box>
                            <Typography variant="h4" gutterBottom className='heder-label'>
                                Who Are <span>We</span>
                            </Typography>
                            <Typography variant="body1" paragraph className='para-label grey-label'>
                                Glam9 is here to digitize and revolutionize your salon and spa experience! We know the bliss of enjoying a relaxing massage after a long week at work and the delight of gossiping with your bff over a plush manicure! And we would love to partake in these little joys of your life! When everything is available at the click of a button – from the prettiest dresses to the fanciest shoes, from your everyday groceries to your favourite food – why should the beauty and wellness industry be left behind? With this goal.
                            </Typography>
                            <Typography variant="body1" paragraph className='para-label grey-label'>
                                Our easy-to-use application allows you to peruse the wide range of services offered by the salons, spas and clinics in your vicinity and hand pick the ones you desire to avail.
                            </Typography>
                            <Typography variant="body1" paragraph className='para-label grey-label'>
                                All you have to do is simply Add to Cart and Book Now, and voila! It’s time to unwind at the spa and adorn at the salon – now at the touch of a button. So put those freshly manicured fingers to good use – book your next appointment through the Glam9.
                            </Typography>
                            <Box sx={{ width: isMatch ? '100%' : 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={gotoPricePage}
                                    className="register-btn"
                                    sx={{
                                        borderRadius: '10px',
                                        backgroundColor: '#0278FB',
                                        padding: '8px 16px',
                                        fontWeight: 400,
                                        marginTop: '10px',
                                        '&:hover': {
                                            backgroundColor: '#025BB5',
                                        }
                                    }}
                                >
                                    Subscribe Now
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>


            </Box>

            <Box
                            className='connection-form-section'
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
            
                            }}
                        >
                            <Box sx={{ width: '100%', maxWidth: '600px' }}>
                                <Typography variant="h4" gutterBottom align="center" className='grey-label' sx={{ fontSize: '18px', fontWeight: 500 }}>
                                Our services
                                </Typography>
                                <Typography variant="h1" sx={{fontSize:'39px'}} gutterBottom align="center" className='connection-label'>
                                Your beauty  goals
                                are our <span style={{fontWeight: '700',color:'#0178fa'}}>priority</span>
                                </Typography>
                                
                                <Grid container spacing={3} justifyContent="center" sx={{ mt: 3 }}>
                                    
                                    
                                    
                                </Grid>
                                
                            </Box>
                        </Box>                        
                        <Grid container spacing={2} className="aboutFeatures">                
                        <ImageList
      sx={{ width: 'auto', height: 'auto' }}
      variant="quilted"
      cols={4}
      rowHeight={'auto'}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
                        </Grid>

                        <Box
                            className='connection-form-section'
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
            
                            }}
                        >
                            <Box sx={{ width: '100%', maxWidth: '600px' }}>
                                <Typography variant="h4" gutterBottom align="center" className='grey-label' sx={{ fontSize: '39px', fontWeight: 500 }}>
                                Our <span style={{color:'#0178fa', fontWeight: '700'}}>Solutions​</span>

                                </Typography>
                                <Typography variant="h1" gutterBottom align="center" className='connection-label' sx={{ fontSize: '17px', fontWeight: 400 }}>
                                A tailored solution for every customers to book services with salon, beauty studio, spa & Make-up Artists
                                </Typography>
                                
                                <Grid container spacing={3} justifyContent="center" sx={{ mt: 3 }}>
                                    
                                    
                                    
                                </Grid>
                                
                            </Box>
                        </Box>
                        <Grid container spacing={2} className="aboutFeatures" justifyContent="space-between">
  {Array.from({ length: 3 }).map((_, index) => (
    <Grid item xs={12} lg={4} xl={4} key={index}>
      <Box
        className="grid1"
        sx={{
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          padding: "16px",
          borderRadius: "8px",
          backgroundColor: "#fff",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
       {/* Salon Text - Bold */}
    <p style={{ textAlign: "left", fontWeight: "400" ,textTransform:"uppercase",margin: "4px 0" }}>
    {index === 0
          ? "Salon & beauty studio"
          : index === 1
          ? "Spa & Wellness Center"
          : "Hair & Nail Studio"}
    </p>
    
    {/* Subtitle - More Bold */}
    <h4 style={{ textAlign: "left", fontWeight: "700", margin: "8px 0"}}>
    {index === 0
          ? "Best salon's & Beauty Studio's in your city"
          : index === 1
          ? "Relax at the best Spa & Wellness Centers"
          : "Top-rated Hair & Nail Studios near you"}
    </h4>
        <img src={
              index === 0
              ? images.grid1
              : index === 1
              ? images.grid2
              : images.grid3
        } style={{ width: "100%", borderRadius: "8px" }} />
      </Box>
    </Grid>
  ))}
</Grid>             

            {/* Feature Container */}
            <Grid container spacing={2} className="aboutFeatures">
                <Typography variant="body1" className="boxTitle" sx={{ marginTop: { xl: '100px', xs: '60px' }, width: "100%", fontSize: { xs: "40px", sm: "40px", lg: "50px", xl: "60px" }, fontWeight: "300", letterSpacing: '0.3px', alignItems: "center", textAlign: 'center' }}>
                    Our  <span style={{ fontWeight: '700' }}>Features</span>
                </Typography>
            </Grid>
            <Grid className='appSection' container spacing={2} sx={{ marginTop: '30px', marginBottom: '40px' }} >
                <Typography variant='button' style={{ float: 'left', width: 'auto' }} sx={{ textAlign: 'center', textTransform: 'capitalize', justifyContent: 'center', margin: '0px auto', color: '#0178FA', background: '#0178FA1A', borderRadius: '60px', padding: '16px' }} >Vendor APP</Typography>
            </Grid>
            <Grid className='appSection' container spacing={2} >
                <img src={images.vendorApp} style={{ margin: '0px auto', width:'-webkit-fill-available', maxWidth:"800px" }} />
            </Grid>
            <Grid className='appSection' container spacing={2} sx={{ marginTop: { xl: '100px', xs: '60px' }, marginBottom: '40px' }} >
                <Typography variant='button' className='aboutButton' sx={{ textAlign: 'center', justifyContent: 'center', margin: '0px auto', textTransform: 'capitalize', color: '#0178FA', background: '#0178FA1A', borderRadius: '60px', padding: '16px' }} >Customer APP</Typography>
            </Grid>
            <Grid className='appSection' container spacing={2} sx={{ marginBottom: '140px' }}>
                <img src={images.vendorApp} style={{ margin: '0px auto', width:'-webkit-fill-available',  maxWidth:"800px" }} />
            </Grid>

        </>

    )
}

export default HeroAbout;