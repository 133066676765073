import { Box, Grid, Typography, Button, useMediaQuery } from '@mui/material';
import React from 'react';
import { images } from '../../Assets/images/index';
import Partners from './Partners';
import { useNavigate } from 'react-router-dom';
import WhyRegister from './WhyRegister';

const HeroMain: React.FC = () => {

    const isMatch = useMediaQuery('(max-width: 900px)');
    const navigate = useNavigate();
    function gotoPricePage(){
        navigate('/pricing');
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }} className='intro-section'>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} order={{ xs: 1, sm: 1, md: 2 }} md={6}>
                        <Box
                            component="img"
                            src={images.heroSectionImage}
                            alt="Sample Image"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px',
                                objectFit: 'cover',
                                pointerEvents: 'none'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} order={{ xs: 2, sm: 2, md: 1 }} md={6}>
                        <Box>
                            <Typography variant="h4" gutterBottom className='introl-label'>
                                Hello, Beauty Entrepreneurs!
                            </Typography>
                            <Typography variant="body1" paragraph className='superchange-label'>
                                Supercharge Your Salon, Spa and Freelance <br />
                                <span className='business-label'>Infinite your beauty business at</span> <br />
                                <span className='platform-label'>India's #1 Super Magic Platform</span>
                            </Typography>
                            <Typography variant="body1" paragraph className='revenue-label grey-label'>
                                Ready to double your revenue and transform your business?
                            </Typography>
                            <Box sx={{ width: isMatch ? '100%' : 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    onClick={gotoPricePage}
                                    color="primary"
                                    size="large"
                                    className="register-btn"
                                    sx={{
                                        borderRadius: '10px',
                                        backgroundColor: '#0278FB',
                                        padding: '8px 16px',
                                        fontWeight: 400,
                                        marginTop: '10px',
                                        '&:hover': {
                                            backgroundColor: '#025BB5',
                                        }
                                    }}
                                >
                                    Subscribe Now
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* Partners */}
            <Partners />
            {/* Why Register */}
            <WhyRegister />
        </>
    )
}


export default HeroMain;