import * as React from "react";
import { Box, Typography, Grid, Button, Paper } from "@mui/material";
import './BeautySpot.css';
import { useNavigate } from 'react-router-dom';

import { icons } from "../../../src/Assets/icons";

interface BeautySpotProp {
  photo: string;
  name: string;
  vendor_detail_id: string;
  rating: string;
  gender: number;
  address: string;
}


const BeautySpot: React.FC<BeautySpotProp> = ({
  photo,
  name,
  vendor_detail_id,
  rating,
  gender,
  address,
}) => {
  const navigate = useNavigate();
  function gotoShop() {
    try {
      localStorage.setItem('shopID', vendor_detail_id.toString());
      navigate('/shop');
    } catch (error) {
      console.error('Error navigating to shop:', error);
    }
  }
  
  console.log("gender",gender);
  

  return (
    <main  className="beautyspot-container">
      <Paper onClick={gotoShop} elevation={3} sx={{cursur:'pointer'}} className="beautyspot-card">
        <Box className="image-section">
          <img
            loading="lazy"
            src={photo}
            className="main-image"
            alt={name}
          />
        </Box>
        <Grid container spacing={0} className="">
          <Grid item xs={8} md={8} >
            <Box className="info-section">
              <Typography variant="h5" className="salon-name">
                {name}
              </Typography>
              <Typography variant="body2" className="salon-type">
                {gender == 1 ? 'Male Salon' : gender == 2 ? 'Female Salon' : 'Unisex Salon'}
              </Typography>
              <Box className="location-container">
                <img
                  loading="lazy"
                  src={icons.yellowLocation}
                  className="location-icon"
                  alt="Location pin icon"
                />
                <Typography component="p" className="location-address">
                  {address}
                </Typography>
              </Box>
            </Box>  
          </Grid>  
          <Grid item xs={4} md={4} className="" >
            <Box className="rating-container">
              <Typography variant="body1" className="rating-text">
                {rating}
              </Typography>
              <img
                loading="lazy"
                src={icons.rating}
                alt="Rating star icon"
                className="rating-star-icon"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
};
export default BeautySpot;
