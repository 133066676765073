import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Avatar,
  Button,
  Menu,
  MenuProps,
  MenuItem,
} from '@mui/material';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import CommonCookie from '../../CommonCookie';
import { useCookies } from 'react-cookie';
import { CustomerAPI } from '../../../../src/hooks/customer';
import Divider from '@mui/material/Divider';
import toast from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { icons } from '../../../../src/Assets/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Header.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useDispatch } from 'react-redux';
import {
  setUserDetails,
  removeUserDetails,
} from '../../../redux/features/userSlice';
import { images } from '../../../../src/Assets/images';
interface Data {
  profile_image?: string; // Optional property
}
const Header = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState<any>(null);
  //   const dispatch = useAppDispatch();
  const [data, setData] = useState();
  const userDetails = useAppSelector(state => state.user.details);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: 'rgb(55, 65, 81)',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[300],
      }),
    },
  }));
  

  const { getCustomerProfileAPICall } = CustomerAPI();
  useEffect(() => {
    const getCustomerProfileFunc = async () => {
      const token = localStorage.getItem('customer_auth_token');
      if (token) {
        const customerDetails = JSON.parse(
          localStorage.getItem('customer_details') as string,
        );
        dispatch(setUserDetails(customerDetails));
        const result: any = await getCustomerProfileAPICall();
        if (result?.status_code === 401) {
          logout();
        } else {
          dispatch(
            setUserDetails({
              ...customerDetails,
              profile_image: result?.data?.profile_image,
            }),
          );
          localStorage.setItem(
            'customer_details',
            JSON.stringify({
              ...customerDetails,
              profile_image: result?.data?.profile_image,
            }),
          );
        }
      }
    };
    getCustomerProfileFunc();
  }, []);
  useEffect(() => {
    setShow(false);
    let currentPage = location.pathname.toString();
    let notAllowedPages: string[] = ['/login', '/register', '/otp'];
    if (!notAllowedPages.some(page => currentPage.includes(page))) {
      setShow(true);
    }
  }, [location]);
  const shouldCheckAuth =
    location.pathname !== '/' &&
    location.pathname !== '/shop' &&
    location.pathname !== '/search' &&
    location.pathname !== '/login' &&
    location.pathname !== '/register' &&
    location.pathname !== '/reviews' &&
    location.pathname !== '/otp' &&
    location.pathname !== '/about' &&
    location.pathname !== '/privacy-policy' &&
    location.pathname !== '/terms-of-services' &&
    location.pathname !== '/terms-of-use' &&
    location.pathname !== '/cookies-policy' &&
    location.pathname !== '/pricing' &&
    location.pathname !== '/vendor';
  if (shouldCheckAuth && !cookies.token && location.pathname !== '/') {
    navigate('/');
  }
  const logout = () => {
    setIsOpen(false);
    removeCookie('token', { path: '/' });
    localStorage.removeItem('customer_auth_token');
    localStorage.removeItem('customer_details');
    dispatch(removeUserDetails());
    navigate('/');
  };
  const getInitials = (name: string) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    let initials =
      nameParts.length > 1
        ? nameParts[0][0] + nameParts[nameParts.length - 1][0]
        : nameParts[0][0]; // Use only the first initial if there's one word
    return initials.toUpperCase();
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /*const getCustomerProfileFunc = useCallback(async () => {
    const result: any = await getCustomerProfileAPICall();
    if (result === null) {
      toast.error('Failed to get customer profile.');
      return;
    }
    if (result?.status_code === 200) {
      setCustomerData(result.data);
    }
  }, [getCustomerProfileAPICall]);
  
  useEffect(() => {
    getCustomerProfileFunc();
  }, [getCustomerProfileFunc]);
  */

  // Go to pricing plan page
  const handlePricing = () => {
    navigate('/pricing');
  };
  return (
    <>
      {show && (
        <>
          <Box
            className={
              location.pathname === '/shop' || location.pathname === '/search'
                ? 'mainHeader shopHeader'
                : 'mainHeader'
            }
            sx={{
              width: '100%',
              top: 0,
              left: 0,
              backgroundColor: '#f6f6f6',
            }}
          >
            <AppBar color="transparent" elevation={0}>
              <Toolbar
                className="header"
                sx={{ justifyContent: 'space-between' }}
              >
                <Link to="/">
                  <img src={images.logo} />
                </Link>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: 0, md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                  >
                    <Link className='mobileOnly' to="/about">
                      <Typography
                        sx={{
                          fontSize: { xs: '10px', sm: '15px', lg: '15px' },
                          fontWeight: '400',
                          marginRight: '7px',
                          color: '#231F20',
                        }}
                      >
                        About
                      </Typography>
                    </Link>
                  </Box>
                  {/*  {location.pathname === '/shop' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              
              <Link to="/shop/cart">Cart</Link>
              <Link to="/shop/profile">Profile</Link>
            </Box>
          )}  */}
                  {!cookies.token && (
                    <>
                      <Link className='mobileOnly' to="/login">
                        <Typography
                          sx={{
                            fontSize: { xs: '10px', sm: '15px', lg: '15px' },
                            marginLeft: '10px',
                            marginRight: '3px',
                            fontWeight: '400',
                            color: '#231F20',
                          }}
                        >
                          Login |
                        </Typography>
                      </Link>{' '}
                      <Link className='mobileOnly' to="/register">
                        <Typography
                          sx={{
                            fontSize: { xs: '10px', sm: '15px', lg: '15px' },
                            marginLeft: '3px',
                            fontWeight: '400',
                            color: '#231F20',
                          }}
                        >
                          Sign Up
                        </Typography>
                      </Link>
                    </>
                  )}
                  <Button
                    className='mobileOnly'
                    sx={{
                      textTransform: 'capitalize',
                      color: '#231F20',
                      fontWeight: '400',
                      fontSize: { xs: '10px', sm: '15px', lg: '15px' },
                      border: '1px solid black',
                      height: { xs: '20px', sm: '30px', lg: '40px' },
                      width: { xs: '100px', sm: '130px', lg: '150px' },
                      borderRadius: '60px',
                    }}
                    onClick={handlePricing}
                  >
                    For Business
                  </Button>
                  {cookies.token ? (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <Tooltip
                          title={
                            userDetails?.first_name +
                            '   ' +
                            userDetails?.last_name
                          }
                        >
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                          >
                            {userDetails?.profile_image ? (
                              <img
                                alt={`${userDetails.first_name} ${userDetails.last_name}'s profile picture`}
                                src={userDetails?.profile_image}
                                className="profileImage"
                              />
                            ) : (
                              <Avatar
                                className="profile-avatar"
                                onClick={handleClose}
                              >
                                {getInitials(
                                  `${userDetails.first_name} ${userDetails.last_name}`,
                                )}
                              </Avatar>
                            )}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </>
                  ) : (
                    <><img
                    src={icons.hamburger}
                    id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleToggleClick}
        className="hamburger"
                  />
                  
                  
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          
        <Link style={{textDecoration:'none'}}         className='mobileOnly' to="/about">
                      <Typography
                        sx={{
                          fontSize: { xs: '10px', sm: '15px', lg: '15px' },
                          fontWeight: '400',
                          marginRight: '7px',
                          color: '#231F20',
                        }}
                      >
                        About
                      </Typography>
                    </Link>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          
        <Link style={{textDecoration:'none'}} className='mobileOnly' to="/login">
                        <Typography
                          sx={{
                            fontSize: { xs: '10px', sm: '15px', lg: '15px' },
                            marginLeft: '0px',
                            marginRight: '3px',
                            fontWeight: '400',
                            color: '#231F20',
                          }}
                        >
                          Login
                        </Typography>
                      </Link>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          
        <Link style={{textDecoration:'none'}} className='mobileOnly' to="/register">
                        <Typography
                          sx={{
                            fontSize: { xs: '10px', sm: '15px', lg: '15px' },
                            marginLeft: '3px',
                            fontWeight: '400',
                            color: '#231F20',
                          }}
                        >
                          Sign Up
                        </Typography>
                      </Link>
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        <MenuItem onClick={handleClose} disableRipple>
          
        <Button
                    className='mobileOnly'
                    sx={{
                      textTransform: 'capitalize',
                      color: '#231F20',
                      fontWeight: '400',
                      fontSize: { xs: '10px', sm: '15px', lg: '15px' },
                      border: '1px solid black',
                      height: { xs: '20px', sm: '30px', lg: '40px' },
                      width: { xs: '100px', sm: '130px', lg: '150px' },
                      borderRadius: '60px',
                    }}
                    onClick={handlePricing}
                  >
                    For Business
                  </Button>
        </MenuItem>
        
      </StyledMenu>


                  </>
                  )}
                  {cookies.token ? (
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      disableScrollLock
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      slotProps={{
                        paper: {
                          elevation: 0,
                          sx: {
                            overflow: 'hidden',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                              borderRadius: 20,
                              maxHeight: 200, // Control dropdown height
                            },
                            '&::before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'transparent',
                              transform: 'translateY(-50%) rotate(45deg)',
                              maxHeight: 200, // Control dropdown height
                              zIndex: 0,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem className="profileName" onClick={handleClose}>
                        {userDetails.first_name} {userDetails.last_name}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          navigate('/profile');
                          handleClose();
                        }}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate('/appointment');
                          handleClose();
                        }}
                      >
                        Appointment
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate('/favourite');
                          handleClose();
                        }}
                      >
                        Favourite
                      </MenuItem>
                      <Divider className="logoutD" />
                      <MenuItem onClick={logout}>Log Out</MenuItem>
                    </Menu>
                  ) : (
                    <></>
                  )}
                </Box>
              </Toolbar>
            </AppBar>
            <div></div>
          </Box>
        </>
      )}
    </>
  );
};
export default Header;
