import { Box, Grid, Button, Typography, useMediaQuery, List, ListItem, ListItemText, Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../../Assets/images/index';

// Array of list items
const businessFeatures = [
    "Get New Business Leads",
    "Manage your online portfolio",
    "Intelligent Business Insights",
    "Receive & Reply to Reviews",
    "Digital Marketing",
];

const WhyRegister: React.FC = () => {

    const isMatch = useMediaQuery('(max-width: 600px)');
    const navigate = useNavigate();
    function gotoPricePage(){
        navigate('/pricing');
    }
    return (
        <>
            <Box sx={{ flexGrow: 1 }} className='why-register-section'>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} order={{ xs: 1, sm: 2, md: 1 }} md={6}>
                        <Box
                            component="img"
                            src={images.whyRegister}
                            alt="Sample Image"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px',
                                objectFit: 'cover',
                                pointerEvents: 'none'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} order={{ xs: 2, sm: 1, md: 2 }} md={6}>
                        <Box>
                            <Typography className='why-register-label'>
                                Why Register on glam9?
                            </Typography>

                            <Typography className='grow-business-label'>
                                Grow Your Business <br /> with <span>Glam9</span>
                            </Typography>

                            <Typography sx={{mt:2}} className='why-register-data grey-label'>
                                Get thousand of verified leads from all over India everyday, grow your business and expand your brand to new customers.
                            </Typography>

                            <Box sx={{ margin: "0 auto", mt:2 }}>
                                <List>
                                    {businessFeatures.map((feature, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem sx={{ px: 0, py:2 }}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                fontWeight: "bold",
                                                                fontSize: "20px",
                                                            }}
                                                        >
                                                            {`${(index + 1).toString().padStart(2, "0")}. ${feature}`}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            {index < businessFeatures.length  && <Divider />}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>

                            <Box sx={{ width: isMatch ? '100%' : 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'20px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={gotoPricePage}
                                    className="register-btn"
                                    sx={{
                                        borderRadius: '10px',
                                        backgroundColor: '#0278FB',
                                        padding: '8px 16px',
                                        fontWeight: 400,
                                        marginTop: '10px',
                                        '&:hover': {
                                            backgroundColor: '#025BB5',
                                        }
                                    }}
                                >
                                    Subscribe Now
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default WhyRegister;