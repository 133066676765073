import React, { useState } from 'react';
import { Box, Typography, Grid, Avatar, Checkbox } from '@mui/material';
import './ServiceItem.css';
import { icons } from '../../../Assets/icons';
import { Service } from '../../../../src/api/types/vendor_services';
import ViewPackageDetailsPopups from '../../../../src/Components/shop/ViewPackageDetailsPopups';
interface serviceItemProps {
  data: any;
  servicesAddRemove: Function;
  serviceIDS: String[];
}
const ServiceItem: React.FC<serviceItemProps> = ({
  data,
  servicesAddRemove,
  serviceIDS,
}) => {
  const [openPackageDetails, setOpenPackageDetails] = useState(false);
  const [packageName, setPackageName] = useState('');
  const [pacakageCharges, setPacakageCharges] = useState('');
  const [pacakageTotalTime, setPacakageTotalTime] = useState('');
  const [pacakageServices, setPacakageServices] = useState<Service[]>([]);

  const handleOpenPackageDetails = (
    name: string,
    total_service_time: string,
    charges: string,
    services: Service[],
  ) => {
    setPacakageCharges(charges);
    setPacakageTotalTime(total_service_time);
    setPacakageServices(services);
    setPackageName(name);
    setOpenPackageDetails(true);
  };
  const handleClosePackageDetails = () => setOpenPackageDetails(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flex: '1 1 auto',
          gap: '10px',
          padding: '14px',
          backgroundColor: 'white',
          borderRadius: '16px',
          border: `1px solid ${data.isSelected ? '#2563EB' : '#292524'}`,
          maxWidth: '100%',
        }}
        className="itemList"
      >
        <Grid
          container
          direction="column"
          onClick={
            data.services && Array.isArray(data.services)
              ? () =>
                  handleOpenPackageDetails(
                    data?.package_name,
                    data?.total_service_time,
                    data.charges,
                    data.services,
                  )
              : undefined
          }
          sx={{
            cursor:
              data.services && Array.isArray(data.services)
                ? 'pointer'
                : 'default',
          }}
        >
          <Typography variant="h5" fontWeight="500">
            {data.service_name || data?.package_name}
          </Typography>
          <Typography variant="body1" mt={1.5} fontWeight="300">
            Process Time: {data.service_time || data?.total_service_time}
          </Typography>
        </Grid>
        <Box
          className="servicePrice"
          sx={{
            display: 'flex',
            gap: '10px',
            marginLeft: 'auto',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            {data.charges ? `₹ ${data.charges}` : 'Free'}
          </Typography>
          <Checkbox
            checked={serviceIDS.includes(data.id) ? true : false}
            icon={
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: '12px',
                  border: '1px solid #292524',
                }}
              />
            }
            checkedIcon={
              <Avatar
                src={icons.bookingBlueTick}
                alt="Selected"
                sx={{ width: 40, height: 40 }}
                variant="square"
              />
            }
            onChange={event => {
              servicesAddRemove(data, event.target.checked);
            }}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 28 }, // Adjust icon size
            }}
          />
        </Box>
      </Box>
      {openPackageDetails && (
        <ViewPackageDetailsPopups
          open={openPackageDetails}
          handleClose={handleClosePackageDetails}
          name={packageName}
          price={pacakageCharges}
          time={pacakageTotalTime}
          services={pacakageServices}
        />
      )}
    </>
  );
};
export default ServiceItem;
