import React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import './PlanTypeButton.css';

interface PlanTypeButtonProps {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const PlanTypeButton: React.FC<PlanTypeButtonProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  const buttons = ['PROFESSIONALS', 'SALONS', 'SPA'];

  return (
    <Box className="group-button" sx={{ width: '100%' }}>
      <ButtonGroup className="group-button-wrapper">
        {buttons.map(label => (
          <Button
            key={label}
            className={`button ${selectedTab === label ? 'selected' : ''}`}
            onClick={() => setSelectedTab(label)}
            sx={{
              fontSize: { xs: '11px', sm: '14px', lg: '16px' },
              fontWeight: { xs: 400, sm: 500, lg: 600 },
            }}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default PlanTypeButton;
