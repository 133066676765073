import React, { useState } from 'react';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
//import LoginForm from './loginForm/loginForm';
import LoginForm from './loginForm/loginForm';
import BrandLogo from './brandLogo/brandLogo';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import AuthService from '../../Services/auth.service';
import { images } from '../../../src/Assets/images';
import './Login.css';
import { useTheme } from '@emotion/react';
const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [emailerror, setEmailerror] = useState('');
  const navigate = useNavigate();
  const authService = AuthService();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery('(max-width:1024px)');
  // const [cookies, setCookie] = useCookies([
  //   CommonCookie.token,
  //   CommonCookie.user,
  //   CommonCookie.userType,
  //   CommonCookie.profile,
  //   CommonCookie.vendor_id,
  // ]);
  // useEffect(() => {
  //   if (cookies.token) {
  //     navigate("/");
  //   }
  // }, [])
  function addEmail() {
    setEmailerror('');
    let isValid = true;

     if (email.length !== 10) {
      setEmailerror('phone number should be 10 digits');
      isValid = false;
    } 
    if (isNaN(parseInt(email))) {
      setEmailerror('Phone Number should be numeric');
      isValid = false;
    } 
    if (email === '') {
      setEmailerror('Please enter your Phone Number');
      isValid = false;
    }

    if (isValid) {
      verifyUser();
    }
  }
  const verifyUser = async () => {
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', email || '');
    try {
      const response = await authService().verifyUser(fd);
       console.log(JSON.stringify(response))

      //return false;
      if (response.data.success) {
        sendOtp();
      } else {
        if(response.data.message == "User is already exists."){
          sendOtp();
        }else
        navigate('/register');
      }
    } catch (error: any) {}
  };

  const sendOtp = async () => {
    console.log(email);
    
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', email || '');
    fd.append('is_web', '1');
    try {
      const response = await authService().sendOtp(fd);

      toast.success(response.data.message);
      //return false;
      if (response.data.success) {
        navigate('/otp');
        navigate('/otp', { state: { number: email } });
      } else {
        navigate('/register');
      }
    } catch (error: any) {} 
  };
  // function backToLanding() {
  //   navigate("/");
  // }
  return (
    <Box className="login">
      <Grid container spacing={2} className="grid-container">
        <Grid item xs={12} md={6} className="login-section">
          <Paper elevation={3} className="login-form-container">
            <LoginForm
              email={email}
              setEmail={setEmail}
              emailerror={emailerror}
              addEmail={addEmail}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className="logo-section">
          <Box className="brand-container">
            <img
              loading="lazy"
              src={images.LogoBg}
              alt=""
              className="brand-image"
            />
            <BrandLogo />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default LoginPage;
