import React from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { icons } from '../../../Assets/icons';
// Interface
import { Service } from '../../../../src/api/types/vendor_services';
type ServiceCardProps = {
  serviceName: string;
  processTime: string;
  professionalName: string;
  getProfesstionalFunc: Function;
  serviceObject: Service;
};

const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[800]}`,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    maxWidth: '70%',
  },
}));

const StyledProfessional = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: '#482E77',
  color: theme.palette.common.white,
  padding: theme.spacing(1),
  borderRadius: '500px',
}));

const ServiceCard: React.FC<ServiceCardProps> = ({
  serviceName,
  processTime,
  professionalName,
  getProfesstionalFunc,
  serviceObject,
}) => {
  return (
    <StyledCard>
      <Box>
        <Typography variant="h6" color="textPrimary" fontWeight="medium">
          {serviceName}
        </Typography>
        <Typography variant="body2" color="textSecondary" mt={1}>
          Process Time: {processTime}
        </Typography>
      </Box>
      <StyledProfessional
        style={{ cursor: 'pointer' }}
        onClick={() => {
          getProfesstionalFunc(serviceObject);
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar
            src={icons.professional}
            alt={professionalName}
            sx={{ width: 44, height: 44 }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            mr={3}
            sx={{ color: 'white', fontSize: '14px' }}
          >
            {professionalName}
          </Typography>
        </Box>
      </StyledProfessional>
    </StyledCard>
  );
};

export default ServiceCard;
