// src/api/userApi.ts

import { apiClient } from './apiClient';
import { ApiResponseFromAPI, DropdownListResponse } from './types/common';

// Get Country List
export const getCountryList = () => {
  return apiClient<ApiResponseFromAPI>(`/get-location`, { method: 'GET' });
};

// Get State List
export const getStateList = (countryId: number) => {
  return apiClient<ApiResponseFromAPI>(`/get-location?countryId=${countryId}`, {
    method: 'GET',
  });
};

// Get Category List
export const getCategoryList = (id: number) => {
  return apiClient<ApiResponseFromAPI>(`/get-categories?use_for=${id}`, {
    method: 'GET',
  });
};

export const getCategory = (id: number) => {
  return apiClient<ApiResponseFromAPI>(`/get-categories`, {
    method: 'GET',
  });
};

// Get City List
export const getCityList = (stateId: number, countryId: number) => {
  return apiClient<ApiResponseFromAPI>(
    `/get-location?stateId=${stateId}&countryId=${countryId}`,
    { method: 'GET' },
  );
};

// Get Dropdown List Options
export const getDropdownListOptions = () => {
  return apiClient<DropdownListResponse>(`/get-dropdown-list`, {
    method: 'GET',
  });
};
