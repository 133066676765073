import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Service } from '../../../src/api/types/services';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700, // Increased width
  height: 500, // Added height
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 0,
  borderRadius: '30px',
  overflowY: 'auto', // Enable scroll if content overflows
};

interface PackageProps {
  open: boolean;
  name?: string;
  time?: string;
  price?: string;
  handleClose: () => void;
  services?: Service[];
}

const ViewPackageDetailsPopups: React.FC<PackageProps> = ({
  open,
  name,
  time,
  price,
  services,
  handleClose,
}) => {
  const [openModal, setOpenModal] = React.useState(open);
  const handleOpen = () => setOpenModal(true);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            onClick={handleClose}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'end',
            }}
          >
            <CloseIcon onClick={handleClose} />
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: 700, fontSize: '22px' }}
          >
            {name}
          </Typography>

          <Box sx={{ mt: 1 }}>
            <Typography sx={{ fontWeight: 500, color: 'rgb(0,0,0,0.5)' }}>  
              Proccess Time : {time}
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'rgb(0,0,0)' }}>
              {price ? `₹${price}` : 'Free'}
            </Typography>
          </Box>

          <Typography sx={{ mt: 2, fontWeight: 600, fontSize: '20px' }}>
            What's included
          </Typography>

          <Box sx={{ mt: 1 }}>
            {services?.map(iteam => {
              return (
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: 'rgb(0,0,0)',
                      fontSize: '16px',
                    }}
                  >
                    {iteam.service_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: 'rgb(0,0,0)',
                      fontSize: '14px',
                    }}
                  >
                    Process Time: {iteam.service_hour}hr
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ViewPackageDetailsPopups;
