// import React from 'react';
// import { Card, CardContent, Typography, Button, Grid, List, ListItem, Box } from '@mui/material';
// import './planCard.css';

// interface PricingCardsProps {
//   prices: number[];
//   isYearly: boolean;
//   description: string[];
//   content: {
//     lite: string[];
//     pro: string[];
//     enterprise: string[];
//   };
// }


// const PricingCards: React.FC<PricingCardsProps> = ({
//   prices,
//   isYearly,
//   description,
//   content,
// }) => {
//   const plans = ['LITE', 'PRO', 'ENTERPRISE'];

//   const handleSubscriptionPurchase = async () => {
//     try {
//       // Set your Vendor Auth Token here
//       const token = `Bearer ${localStorage.getItem('customer_auth_token')}` || "";
//       console.log(token)
//       const subscriptionId = 1; // Replace with actual subscription ID
//       const apiBaseUrl = 'https://edc0-2401-4900-8899-393e-341b-5222-1901-ec33.ngrok-free.app/api/v1' + '/purchase-subscription';

//       // Make the API request
//       const response = await fetch(apiBaseUrl, {
//         method: "POST",
//         headers: {
//           Authorization: token,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           subscription_id: subscriptionId, // Request Body
//         }),
//       });

//       // Parse the JSON response
//       const data = await response.json();

//       // Check success status
//       if (data.success) {
//         console.log("Response Data:", data);

//         // Example: Redirect to payment URL
//         window.location.href = data.data.payment_url;
//       } else {
//         console.error("Purchase Failed:", data.message);
//       }
//     } catch (error) {
//       console.error("API Error:", "catch");
//     }
//   }

//   return (
//     <Grid container spacing={3} justifyContent="center">
//       {plans.map((plan, index) => {
//         const planKey = plan.toLowerCase() as keyof typeof content;
//         return (
//           <Grid item xs={12} sm={4} key={plan}>
//             <Card className="plan-card">
//               <CardContent className="plan-card-wrapper">
//                 <Typography className="plan-type">{plan}</Typography>
//                 <Typography variant="h4" sx={{ fontWeight: 'bold', my: 1 }}>
//                   ₹{prices[index]}
//                   <span id="package"> {isYearly ? '/yr' : '/mo'}</span>
//                 </Typography>
//                 <Typography id="package">
//                   billed {isYearly ? 'yearly' : 'Monthly'}
//                 </Typography>
//                 <Typography
//                   className="plan-description"
//                   sx={{ minHeight: '50px' }}
//                 >
//                   {description[index]}
//                 </Typography>
//                 <Button onClick={handleSubscriptionPurchase} className="plan-button">Subscribe Now</Button>
//                 <Box className="free-plan-box">
//                   <Typography className="free-plan">
//                     <span
//                       className="free-plan-wrapper"
//                       style={{ marginRight: '4px' }}
//                     >
//                       ₹500
//                     </span>
//                     Setup Fee
//                   </Typography>
//                 </Box>
//                 <ul>
//                   {content[planKey].map((feature, idx) => (
//                     <li key={idx} className="feature-item">
//                       {feature}
//                     </li>
//                   ))}
//                 </ul>
//               </CardContent>
//             </Card>
//           </Grid>
//         );
//       })}
//     </Grid>
//   );
// };

// export default PricingCards;

import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@mui/material";
import "./planCard.css";

interface PricingCardsProps {
  prices: number[];
  isYearly: boolean;
  description: string[];
  content: {
    lite: string[];
    pro: string[];
    enterprise: string[];
  };
  selectedTab:String
}

const states = [
  { id: 1, state_name: "Andaman and Nicobar Islands" },
  { id: 2, state_name: "Andhra Pradesh" },
  { id: 3, state_name: "Arunachal Pradesh" },
  { id: 4, state_name: "Assam" },
  { id: 5, state_name: "Bihar" },
  { id: 6, state_name: "Chandigarh" },
  { id: 7, state_name: "Chhattisgarh" },
  { id: 8, state_name: "Dadra and Nagar Haveli" },
  { id: 9, state_name: "Daman and Diu" },
  { id: 10, state_name: "Delhi" },
  { id: 11, state_name: "Goa" },
  { id: 12, state_name: "Gujarat" },
  { id: 13, state_name: "Haryana" },
  { id: 14, state_name: "Himachal Pradesh" },
  { id: 15, state_name: "Jammu and Kashmir" },
  { id: 16, state_name: "Jharkhand" },
  { id: 17, state_name: "Karnataka" },
  { id: 19, state_name: "Kerala" },
  { id: 20, state_name: "Lakshadweep" },
  { id: 21, state_name: "Madhya Pradesh" },
  { id: 22, state_name: "Maharashtra" },
  { id: 23, state_name: "Manipur" },
  { id: 24, state_name: "Meghalaya" },
  { id: 25, state_name: "Mizoram" },
  { id: 26, state_name: "Nagaland" },
  { id: 29, state_name: "Odisha" },
  { id: 31, state_name: "Pondicherry" },
  { id: 32, state_name: "Punjab" },
  { id: 33, state_name: "Rajasthan" },
  { id: 34, state_name: "Sikkim" },
  { id: 35, state_name: "Tamil Nadu" },
  { id: 36, state_name: "Telangana" },
  { id: 37, state_name: "Tripura" },
  { id: 38, state_name: "Uttar Pradesh" },
  { id: 39, state_name: "Uttarakhand" },
  { id: 41, state_name: "West Bengal" },
];

const PricingCards: React.FC<PricingCardsProps> = ({
  prices,
  isYearly,
  description,
  content,
  selectedTab,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectPlan, setSelectPlan] = useState("");
  const [formData, setFormData] = useState({
    billing_name: "",
    billing_address: "",
    billing_state: "",
    billing_country: "India",
    billing_tel: "",
    billing_email: "",
  });


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOpenForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };
   
  console.log(selectedTab)

  const handleFormSubmit = async () => {
    console.log("Form Submitted: ", formData);
    // const apiBaseUrl = `https://d5ec-2401-4900-1c80-d7ad-e074-eb1d-2422-78b8.ngrok-free.app/api/v1/purchase-subscription`;
    const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/purchase-subscription`;

   var subscription_id

    if(selectedTab == "" || selectedTab == "PROFESSIONALS"){
       if(selectPlan == "LITE"){
          subscription_id= "10"
       }
       if(selectPlan == "PRO"){
        subscription_id= "11"
       }
       if(selectPlan == "ENTERPRISE"){
        subscription_id= "12"
       }

    }

    if(selectedTab == "SALONS"){
      if(selectPlan == "LITE"){
        subscription_id= "7"
     }
     if(selectPlan == "PRO"){
      subscription_id= "8"
     }
     if(selectPlan == "ENTERPRISE"){
      subscription_id= "9"
     }
    }

    if(selectedTab == "SPA"){
      if(selectPlan == "LITE"){
        subscription_id= "16"
     }
     if(selectPlan == "PRO"){
      subscription_id= "17"
     }
     if(selectPlan == "ENTERPRISE"){
      subscription_id= "18"
     }
    }
  
  
    try {
      const response = await fetch(apiBaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription_id: subscription_id, // Static subscription ID as per your request
          billing_name: formData.billing_name,
          billing_address: formData.billing_address,
          billing_state: formData.billing_state,
          billing_country: formData.billing_country,
          billing_tel: formData.billing_tel,
          billing_email: formData.billing_email,
        }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        console.log("Purchase Successful:", JSON.stringify(data));
  
        // Encrypt the request data using AES encryption
        const encryptedData = data.data.request; // Implement the encryption function
        const paymentURL = data.data.payment_url; // Implement the encryption function

        console.log('mehul',data.data.access_code,encryptedData )
  
        // Construct the CCAvenue payment URL
        const paymentUrl = `https://secure.ccavenue.com/transaction.do?command=initiateTransaction&encRequest=${encryptedData}&access_code=${data.data.access_code}`;
          console.log('mehul',data.data.access_code,encryptedData )

        // Redirect to the CCAvenue payment page
        window.location.href = paymentUrl;
      } else {
        console.error("Purchase Failed:", data.message);
        alert("Purchase Failed: " + data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      alert("Something went wrong. Please try again.");
    }
    handleCloseForm();
  };
  
  const plans = ["LITE", "PRO", "ENTERPRISE"];

  return (
    <Grid container spacing={3} justifyContent="center">
      {plans.map((plan, index) => {
        const planKey = plan.toLowerCase() as keyof typeof content;
        return (
          <Grid item xs={12} sm={4} key={plan}>
            <Card className="plan-card">
              <CardContent className="plan-card-wrapper">
                <Typography className="plan-type">{plan}</Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold", my: 1 }}>
                  ₹{prices[index]}
                  <span id="package"> {isYearly ? "/yr" : "/mo"}</span>
                </Typography>
                <Typography id="package">
                  billed {isYearly ? "yearly" : "Monthly"}
                </Typography>
                <Typography><strong>
                  Onetime Setup Fee: ₹500 (included)
                </strong>
                </Typography>
                <Typography
                  className="plan-description"
                  sx={{ minHeight: "50px" }}
                >
                  {description[index]}
                </Typography>
                <Button onClick={() => { handleOpenForm(); setSelectPlan(plans[index]); }} className="plan-button">
                  Subscribe Now
                </Button>
              </CardContent>
            </Card>
          </Grid>
        );
      })}

      {/* Billing Form Modal */}
      <Dialog open={showForm} onClose={handleCloseForm}>
        <DialogTitle>Billing Information</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 ,width: '500px'}}>
            <TextField
              label="Name"
              name="billing_name"
              value={formData.billing_name}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Address"
              name="billing_address"
              value={formData.billing_address}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              select
              label="State"
              name="billing_state"
              value={formData.billing_state}
              onChange={handleInputChange}
              fullWidth
            >
              {states.map((state) => (
                <MenuItem key={state.id} value={state.state_name}>
                  {state.state_name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Country"
              name="billing_country"
              value={formData.billing_country}
              fullWidth
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Phone"
              name="billing_tel"
              value={formData.billing_tel}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Email"
              name="billing_email"
              value={formData.billing_email}
              onChange={handleInputChange}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default PricingCards;



